








import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import { UserStoreModule } from '@common-src/store/modules/user';

@Component
export default class PermissionDenyComponent extends BaseComponent {
    loginClick() {
        UserStoreModule.SET_USERINFO();
        this.$router.push('/sign/login');
    }
}
